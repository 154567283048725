<template>
  <div>
    <appheader index="3"></appheader>
    <div class="wrap">
      <div class="wrap-content">
        <div class="box_btoom">
          <div class="box_weixin">联系我们</div>
          <div class="box_dianhua">
            <svg-icon iconClass="dianhua-2" className="dianhua"></svg-icon>
            <span style="padding-left: 10px">86 - 755 - 25607666 25509955</span>
          </div>
          <div class="box_dianhua">
            <svg-icon iconClass="dizhi" className="dianhua"></svg-icon>
            <span style="padding-left: 10px">深圳市罗湖区翠竹北路石化工业区1栋1-4楼</span>
          </div>
        </div>
        <div>
          <div class="box_weixin">
            微信公众号
          </div>
          <div class="box_weixin_img">
            <img src="../../Img/detaile/dimensionalcode.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
export default {
  name: 'Collection',
  data() {
    return {};
  },
  components: {
    Appheader,
    bottom,
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.wrap {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 880px;
}
.wrap-content {
  max-width: 1500px;
  margin: 0 auto;
  padding-bottom: 30px;
}
.box_btoom {
  padding-top: 44px;
  font-size: 24px;
  color: #fff;
}
.box_weixin_img {
  width: 120px;
  height: 120px;
  margin-top: 18px;
}
.box_weixin {
  padding-top: 44px;
  font-size: 24px;
  color: #ec9368;
}
.box_dianhua {
  font-size: 16px;
  margin-top: 24px;
  color: #ec9368;
}
</style>
